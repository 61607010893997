import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MenuItem from "../../Header/components/menu-item/menu-item";
import MenuItemNotification from "../../Header/components/menu-notification/menu-notification";
import {
    needsToFillPlacement,
    bootcamperNeedToContinue,
    getUserOS,
} from "@/utils/utilFunctions";
import Verticals from "@/core/enums/verticals.enum";
import { Portal } from "@material-ui/core";
import ScalersSnackbar from "@/components/molecules/snackbar/Snackbar";
import { CurrUserDetails } from "@/utils/currUserType";
import useVerticalButton from "./hooks/useVerticalButton";
import { getPath, getVerticalByPath } from "./utils";
import { accounting_v3_flag } from "@/helpers/feature-flag.helper";
interface MenuItemsProps {
    urls: Array<{ path: string; name: string; icon: React.ComponentType }>;
    currentUser: CurrUserDetails;
    isSideBarOpen: boolean;
    selectedVertical: Verticals;
    messagesNotify: boolean;
    curatedListCount: number;
    jobApplicants: number;
    locale: string;
}

const isFeatureFlagEnable = (vertical: number, name: string = ""): boolean => {
    const specialCaseRoute = ["candidate-list"];

    switch (vertical) {
        case Verticals.Accounting:
            if (specialCaseRoute.includes(name)) {
                return false;
            }
            return !accounting_v3_flag();
        default:
            return false; // By default, feature flag is not Enable
    }
};

const MenuItems: React.FC<MenuItemsProps> = ({
    urls,
    currentUser,
    isSideBarOpen,
    selectedVertical,
    messagesNotify,
    curatedListCount,
    jobApplicants,
    locale,
}) => {
    const { t } = useTranslation();
    const [isDemoSnackbarOpen, setIsDemoSnackbarOpen] = useState(false);
    const { pathname } = useLocation();
    const os = getUserOS();
    const { variant } = useVerticalButton(selectedVertical);
    const activeUrls =
        currentUser.name === undefined
            ? urls.filter((route) => route.isPublic)
            : urls;

    // Get the path type from `pathname`, map it to the corresponding vertical,
    // and check if the feature flag for the vertical is enabled.
    const path = getPath(pathname); // Path might be undefined
    const vertical = getVerticalByPath(path!);

    return (
        <div
            className={clsx("sidebar", {
                [`sidebar--${path}`]: path !== undefined,
                expanded: isSideBarOpen,
            })}
        >
            {activeUrls.map((link, index) => {
                {
                    const isEnable = isFeatureFlagEnable(vertical, link.name);
                    return (
                        <MenuItem
                            key={index}
                            to={
                                currentUser.name === undefined ||
                                needsToFillPlacement(currentUser) ||
                                bootcamperNeedToContinue(currentUser) ||
                                isEnable
                                    ? "#"
                                    : link.path
                            }
                            text={t(link.name)}
                            variant={variant ?? "default"}
                            active={pathname.includes(link.path)}
                            onClick={(
                                e: React.MouseEvent<
                                    HTMLAnchorElement,
                                    MouseEvent
                                >
                            ) => {
                                if (isEnable) {
                                    e.preventDefault();
                                    setIsDemoSnackbarOpen(true);
                                    return;
                                }
                                if (
                                    needsToFillPlacement(currentUser) ||
                                    bootcamperNeedToContinue(currentUser)
                                ) {
                                    e.preventDefault();
                                    document.getElementById(
                                        "you-need-to-complete-profile"
                                    )!.style.display = "flex";
                                }
                                if (pathname.includes(link.path)) {
                                    e.preventDefault();
                                }
                                if (currentUser.name === undefined) {
                                    document.getElementById(
                                        "login-job-modal"
                                    )!.style.display = "flex";
                                }
                                return;
                            }}
                            notification={
                                <MenuItemNotification
                                    os={os}
                                    locale={locale}
                                    link={link}
                                    messagesNotify={messagesNotify}
                                    curatedListCount={curatedListCount}
                                    jobApplicants={jobApplicants}
                                    isSideBarOpen={isSideBarOpen}
                                />
                            }
                            isSideBarOpen={isSideBarOpen}
                            testId={link.name}
                        >
                            <link.icon fontSize="small" />
                            <Portal>
                                <ScalersSnackbar
                                    showing={isDemoSnackbarOpen}
                                    handleClose={() => {
                                        setIsDemoSnackbarOpen(false);
                                    }}
                                    type="info"
                                    variant="outlined"
                                    hideDuration={4000}
                                    relativeHorizontal="center"
                                    vertical="top"
                                    showIcon={false}
                                >
                                    {t("feature_soon")}
                                </ScalersSnackbar>
                            </Portal>
                        </MenuItem>
                    );
                }
            })}
        </div>
    );
};

export default MenuItems;
