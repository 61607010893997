/* eslint-disable no-useless-escape */
import React from "react";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import axios from "axios";
import singletonLocalStorage from "./localStoring";
import axiosInstance from "../../static/axios";
import { FeatureFlag } from "@/feature-flag";
import { CandidateProfileStatusEnum } from "./enums";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { JobStatusEnumsById } from "./jobs/jobformutils";
import { captureEvent } from "@/mixpanel-tracking/Mixpanel";
import { prefix_to_object } from "@/mixpanel-tracking/utils";

export const ftBackendStageSync = (stageId, isOldJourney) => {
    let key = stageId;
    if (isOldJourney) {
        return key;
    }
    switch (key) {
        case 5:
            key = 2;
            break;
        case 2:
            key = 4;
            break;
        default:
            break;
    }
    return key;
};

export const ShortlistSource = {
    CANDIDATE_LIST: 1,
    CANDIDATE_PAGE: 2,
    CURATED_PAGE: 3,
    JOB_POST: 4,
};

export const EditMode = {
    OFF: 0,
    EMAIL: 1,
    PASSWORD: 2,
    PHONE: 3,
};

export const getSalaryRange = (salary_range) => {
    return (
        // (salary_range[0] / 1000).toString() +
        // " - " +
        (salary_range[1] / 1000).toString()
    );
};

export function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function reminderHasPassed(date) {
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    return new Date(date) < today;
}

export const getObjectDiff = (obj1, obj2, compareRef = false) => {
    return Object.keys(obj1).reduce((result, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
            // eslint-disable-next-line no-undef
        } else if (_.isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);

            if (compareRef && obj1[key] !== obj2[key]) {
                result[resultKeyIndex] = `${key} (ref)`;
            } else {
                result.splice(resultKeyIndex, 1);
            }
        }
        return result;
    }, Object.keys(obj2));
};

export const IOSSwitch = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor:
                    theme.palette.mode === "dark" ? "#5c369b" : "#5c369b",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 18,
        height: 18,
    },
    "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

export const candidateListCheckStorage = (getCandidates, setPage, filters) => {
    const localPage = parseInt(singletonLocalStorage.getItem("curr_page"));
    if (localPage) {
        setPage(localPage);
        getCandidates(false, filters, localPage);
    } else {
        getCandidates(false, filters, 1);
    }
};

export const isValidHttpURL = (link) => {
    var res =
        /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gi.test(
            link
        );
    return res;
};

export const isValidEmail = (email) => {
    var res =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        );
    return res;
};

function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
}

export const secondsToString = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${padTo2Digits(minutes)}:${padTo2Digits(remainingSeconds)}`;
};

export const getLookups = (setLookups, candidate) => {
    if (FeatureFlag.enable_placement_form_filters()) {
        const fetchEmployers = axiosInstance.get(
            `${import.meta.env.VITE_BASE_URL}/applicant/employers`
        );
        const fetchJobTitles = axiosInstance.get(
            `${import.meta.env.VITE_BASE_URL}/applicant/job-titles`
        );
        const fetchSkills = axios.get(
            `${import.meta.env.VITE_LOOKUP_SERVICE}lookup/skills`
        );

        Promise.allSettled([fetchEmployers, fetchJobTitles, fetchSkills]).then(
            (results) => {
                const fulfilledResults = results.filter(
                    (result) => result.status === "fulfilled"
                );
                const rejectedResults = results.filter(
                    (result) => result.status === "rejected"
                );

                if (fulfilledResults.length > 0) {
                    const [employersRes, jobTitlesRes, skillsRes] =
                        fulfilledResults.map((result) => result.value);

                    setLookups((prev) => ({
                        ...prev,
                        employers:
                            (employersRes &&
                                employersRes.data.map((employer) => ({
                                    id: employer,
                                    name_en: employer,
                                    name_ar: employer,
                                }))) ||
                            [],
                        job_titles:
                            (jobTitlesRes &&
                                jobTitlesRes.data.map((job_title) => ({
                                    id: job_title,
                                    name_en: job_title,
                                    name_ar: job_title,
                                }))) ||
                            [],
                        skills: (skillsRes && skillsRes.data) || [],
                    }));
                }

                if (rejectedResults.length > 0) {
                    // Handle errors here if needed
                    console.error(
                        "Error fetching data:",
                        rejectedResults.map((result) => result.reason)
                    );
                }
            }
        );
    }
    axios
        .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/user-tags/1`)
        .then((res) => {
            setLookups((prev) => ({
                ...prev,
                sales_tags: res.data,
            }));
        });

    axios
        .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/user-tags/2`)
        .then((res) => {
            setLookups((prev) => ({
                ...prev,
                marketing_tags: res.data,
            }));
        });
    axios
        .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/user-tags/3`)
        .then((res) => {
            setLookups((prev) => ({
                ...prev,
                accounting_tags: res.data,
            }));
        });
    axios
        .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/cities`)
        .then((res) => {
            let ind = res.data.findIndex((o) => o.name_en === "Other");

            res.data.push(res.data.splice(ind, 1)[0]);
            setLookups((prev) => ({
                ...prev,
                cities: res.data,
            }));
        });
    axios
        .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/industries`)
        .then((res) => {
            let ind = res.data.findIndex((o) => o.name_en === "Other");

            res.data.push(res.data.splice(ind, 1)[0]);
            setLookups((prev) => ({
                ...prev,
                top_industries: res.data,
            }));
        });
    axios
        .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/majors`)
        .then((res) => {
            let ind = res.data.findIndex((o) => o.name_en === "Other");

            res.data.push(res.data.splice(ind, 1)[0]);
            setLookups((prev) => ({
                ...prev,
                majors: res.data,
            }));
        });
    axios
        .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/salary-expectations`)
        .then((res) => {
            setLookups((prev) => ({
                ...prev,
                salary_expectations: res.data,
            }));
        });
    axios
        .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/job-types`)
        .then((res) => {
            setLookups((prev) => ({
                ...prev,
                job_types: res.data,
            }));
        });
    axios
        .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/universities`)
        .then((res) => {
            let ind = res.data.findIndex((o) => o.name_en === "Other");

            res.data.push(res.data.splice(ind, 1)[0]);
            setLookups((prev) => ({
                ...prev,
                universities: res.data,
            }));
        });
    axios
        .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/job-benefits`)
        .then((res) => {
            let ind = res.data.findIndex((o) => o.name_en === "Other");

            res.data.push(res.data.splice(ind, 1)[0]);
            setLookups((prev) => ({
                ...prev,
                job_benefits: res.data,
            }));
        });
    axios
        .get(
            `${import.meta.env.VITE_LOOKUP_SERVICE}lookup/job-disqualified-reasons?vertical_id=1`
        )
        .then((res) => {
            setLookups((prev) => ({
                ...prev,
                job_disqualify_reasons: res.data,
            }));
        });
    axios
        .get(
            `${import.meta.env.VITE_LOOKUP_SERVICE}lookup/job-disqualified-reasons?vertical_id=2`
        )
        .then((res) => {
            setLookups((prev) => ({
                ...prev,
                marketing_job_disqualify_reasons: res.data,
            }));
        });
    axios
        .get(
            `${import.meta.env.VITE_LOOKUP_SERVICE}lookup/job-disqualified-reasons?vertical_id=3`
        )
        .then((res) => {
            setLookups((prev) => ({
                ...prev,
                accounting_job_disqualify_reasons: res.data,
            }));
        });
    axios
        .get(
            `${import.meta.env.VITE_LOOKUP_SERVICE}lookup/lkp-job-close-reasons-v2`
        )
        .then((res) => {
            setLookups((prev) => ({
                ...prev,
                job_close_reasons: res.data,
            }));
        });
    axios
        .get(
            `${import.meta.env.VITE_LOOKUP_SERVICE}lookup/popular-hiring-sources`
        )
        .then((res) => {
            setLookups((prev) => ({
                ...prev,
                popular_hiring_sources: res.data,
            }));
        });
    axiosInstance.get("job/icp-questions?vertical=1").then((res) => {
        setLookups((prev) => ({
            ...prev,
            icp_questions: res.data,
        }));
    });

    if (candidate) {
        axios
            .get(`${import.meta.env.VITE_LOOKUP_SERVICE}lookup/english-level`)
            .then((res) => {
                setLookups((prev) => ({
                    ...prev,
                    english_proficiencies: res.data,
                }));
            });
    }
};

export const listFormat = (list, t) => {
    return (
        <ul>
            {list?.map((item, index) => {
                return <li key={index}>{t(item)}</li>;
            })}
        </ul>
    );
};

export const getUserOS = () => {
    const os = ["Windows", "Linux", "Mac"]; // add your OS values
    return os.find((v) => navigator.platform.indexOf(v) >= 0);
};

export const checkAndSetJobNotification = (
    job_applicants,
    jobApplicants,
    setJobApplicants
) => {
    if (
        singletonLocalStorage.getItem("show_banner") &&
        singletonLocalStorage.getItem("show_banner") == "false"
    ) {
        if (job_applicants?.total_number > jobApplicants.total_number) {
            singletonLocalStorage.setItem("show_banner", "true");
            job_applicants["show_banner"] = true;
        } else {
            job_applicants["show_banner"] = false;
        }
    } else if (job_applicants?.total_number > 0) {
        singletonLocalStorage.setItem("show_banner", "true");
        job_applicants["show_banner"] = true;
    }
    setJobApplicants(job_applicants);
};

export const clearSpecificsFromLocal = () => {
    singletonLocalStorage.removeItem("refresh_token");
    singletonLocalStorage.removeItem("access_token");
    singletonLocalStorage.removeItem("filters");
    singletonLocalStorage.removeItem("show_banner");
    singletonLocalStorage.removeItem("curr_page");
    singletonLocalStorage.removeItem("candidates_list");
};

export const updateOnboardingStatus = (status) => {
    axiosInstance
        .patch("/user/update-onboarding-status/", {
            onboarding_journey_status: status,
        })
        .then((res) => {
            console.log("Response:", res.data);
        })
        .catch((err) => console.log(err));
};

export const OnboardingStatus = {
    NONE: null,
    STARTED: "started",
    IN_PROGRESS: "in-progress",
    COMPLETED: "completed",
    SKIPPED: "skipped",
};

export const isOnboarding = (currentUser) => {
    return (
        currentUser.views === "hp" &&
        currentUser.onboarding_journey_status !== OnboardingStatus.SKIPPED &&
        (currentUser.onboarding_journey_status ===
            OnboardingStatus.IN_PROGRESS ||
            currentUser.onboarding_journey_status === OnboardingStatus.STARTED)
    );
};

export const genderOptions = [
    {
        id: 1,
        name_en: "Male",
        name_ar: "ذكر",
    },
    {
        id: 2,
        name_en: "Female",
        name_ar: "أنثى",
    },
];

export const noticePeriodOptions = [
    {
        id: 1,
        name_en: "Start immediately",
        name_ar: "بدء فوري",
    },
    {
        id: 2,
        name_en: "Within a month",
        name_ar: "خلال شهر",
    },
    {
        id: 3,
        name_en: "Within 2 months",
        name_ar: "خلال شهرين",
    },
];

export const citiesToRelocate = [
    {
        id: 11,
        name_en: "Riyadh",
        name_ar: "الرياض",
    },
    {
        id: 19,
        name_en: "Jeddah",
        name_ar: "جدة",
    },
    {
        id: 28,
        name_en: "Eastern Province",
        name_ar: "المنطقة الشرقية",
    },
];

export const beforeSelectedMonth = (date, selectedDate) => {
    if (selectedDate) {
        const day = new Date(date).getTime();
        const dateSelected = new Date(selectedDate).getTime();
        return dateSelected > day;
    }
    return false;
};

export const afterSelectedMonth = (date, selectedDate) => {
    if (selectedDate) {
        const day = new Date(date).getTime();
        const dateSelected = new Date(selectedDate).getTime();
        return dateSelected < day;
    }
    return false;
};

export const showSuccess = (successId, time) => {
    document.getElementById(successId).style.display = "flex";
    setTimeout(() => {
        document.getElementById(successId).style.display = "none";
    }, time);
};

export const needsToFillPlacement = (data) => {
    return (
        (data.views === "candidate" ||
            (data.views === "fast-tracker" &&
                data.profile_status ===
                    CandidateProfileStatusEnum.PENDING_VIDEO_SUBMISSION)) &&
        data.notice_period == null
    );
};

export const bootcamperNeedToContinue = (data) => {
    return (
        data.views === "candidate" &&
        data.profile_status ===
            CandidateProfileStatusEnum.PENDING_EXP_AND_PREFERENCES
    );
};

export const CandidateProfilePreference = {
    OLD: "OLD",
    NEW: "NEW",
};

export const CandidateProfileTabsMap = {
    0: "Candidate Information",
    1: "Resume",
    2: "Protofolio",
    3: "Participant Report",
    4: "Comment",
};

export const calculateElapsedSeconds = (startTime) => {
    const currentTime = new Date();
    const elapsedTimeInMilliseconds = currentTime - startTime;
    const elapsedSeconds = Math.floor(elapsedTimeInMilliseconds / 1000);
    return elapsedSeconds;
};

export const jobSpecsData = (job, locale, t) => [
    {
        name: job.location && job.location[`name_${locale}`],
        Icon: LocationOnOutlinedIcon,
        label: "location",
    },
    {
        name: job?.workplace_type?.[`name_${locale}`],
        Icon: MapsHomeWorkOutlinedIcon,
        label: "workplace-type",
    },
    {
        name: job.type[`name_${locale}`],
        Icon: AccessTimeOutlinedIcon,
        label: "type",
    },
    {
        name: `${getSalaryRange(job.salary_range)} ${t("k")}`,
        Icon: PaymentsOutlinedIcon,
        label: "salary-range",
    },
];

export const pipelineCounterImageMap = {
    pending_candidates: PeopleAltOutlinedIcon,
    short_listed_candidates: ThumbUpAltOutlinedIcon,
    interview_scheduled: CalendarTodayOutlinedIcon,
    interview_completed: MeetingRoomOutlinedIcon,
    offer_sent: HourglassEmptyOutlinedIcon,
    hired: CelebrationIcon,
};

export const emptyCandidateListText = [
    {
        heading: "job-application.job_pipeline_counters.0-heading",
        subheading: "job-application.job_pipeline_counters.0-subheading",
    },
    {
        heading: "job-application.job_pipeline_counters.1-heading",
    },
    {
        heading: "job-application.job_pipeline_counters.2-heading",
    },
    {
        heading: "job-application.job_pipeline_counters.3-heading",
    },
    {
        heading: "job-application.job_pipeline_counters.4-heading",
    },
    {
        heading: "job-application.job_pipeline_counters.5-heading",
    },
];

export const pipelineCounterIndexMap = {
    pending_candidates: -1,
    short_listed_candidates: 0,
    interview_scheduled: 1,
    interview_completed: 2,
    offer_sent: 3,
    hired: 4,
};

export const jobSources = {
    1: "CANDIDATE_LIST",
    2: "CANDIDATE_PAGE",
    3: "CURATED_LIST",
    4: "JOB_POST",
    5: "JOB_CURATIONS",
};

export const checkGenericNotificationAllowedUrls = (url) => {
    const allowedUrls = [
        "applicant",
        "candidates",
        "user",
        "company",
        "employee",
        "job",
        "jobs",
        "generic-candidates",
    ];
    const notAllowedUrls = [
        "company/notifications",
        "cronofy/calendar-auth/",
        "employee_email_login/verify/",
        "employee_phone_login/verify/",
        "job/v3/job-description-suggestion",
        "job/v3/suggested-fields",
    ];

    // Check if the url matches any of the not allowed urls
    for (let i = 0; i < notAllowedUrls.length; i++) {
        if (url.includes(notAllowedUrls[i])) {
            return false;
        }
    }

    // Check if the url matches any of the allowed urls
    for (let i = 0; i < allowedUrls.length; i++) {
        if (url.includes(allowedUrls[i])) {
            return true;
        }
    }

    return false;
};

export const jobAutoResumeEvent = (job, currentUser, type) => {
    const isJobPaused = JobStatusEnumsById[job.status.id] === "paused";

    if (isJobPaused) {
        const pausedJobEventData = {
            name: "Job is Resumed",
            eventProps: {
                manually: false,
                ...prefix_to_object(currentUser, "hp_"),
                ...prefix_to_object(job, "job_"),
                type_of_action: type,
            },
        };
        captureEvent(pausedJobEventData.name, pausedJobEventData.eventProps);
    }
};
